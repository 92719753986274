export default defineNuxtRouteMiddleware(async (to, from) => {
  const path = to.path;

  if (path.includes('Vest')) {
    return navigateTo(path.replace('Vest', 'sovet'));
  }

  if (to.name?.toString().includes('advices-adviceId-slug')) {
    const legacyArticleResolverUrl = '/articles/get-uuid';
    const adviceId = to.params.adviceId.toString();
    const slug = to.params.slug;
    const isNumericId = /^\d+$/.test(adviceId);
    if (isNumericId) {
      const response = await apiFetch(`${legacyArticleResolverUrl}?id=${adviceId}`);
      const adviceUuid = response.uuid;
      return navigate({
        name: 'advices-adviceId-slug',
        params: { adviceId: adviceUuid, slug: slug },
      });
    }
  }
});
